import "core-js/modules/es.array.push.js";
import { showToast } from 'vant';
import 'vant/es/toast/style';
export default {
  data() {
    return {
      goods: {
        title: 'OGAI',
        price: 2680,
        express: '免运费',
        remain: 19,
        thumb: ['/static/imgs/b1.jpeg', '/static/imgs/b2.png']
      }
    };
  },
  methods: {
    formatPrice() {
      return '¥' + (this.goods.price / 100).toFixed(2);
    },
    onClickCart() {
      this.$router.push('cart');
    },
    sorry() {
      showToast('Coming soon.');
    }
  }
};